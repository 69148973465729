.faq {
  padding: 5rem 0;
  line-height: 2.4rem;

  @include desktop {
    padding: 10rem 0;
    font-size: 3rem;
    line-height: 3.6rem;
  }

  h3 {
    margin-bottom: 3rem;
    font-size: 3.6rem;
    line-height: 3.8rem;
    letter-spacing: -0.49px;

    @include desktop {
      margin-bottom: 6rem;;
      font-size: 6.5rem;
      line-height: 7.7rem;
      letter-spacing: -0.88px;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: left;
  }

  p {
    margin: 0 0 3rem;
  }

  .question {
    border-bottom: 2px solid $color-white;
  }

  .answer {
    display: block;
    font-weight: normal;
    margin-top: 0.75rem;
  }
}