*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
}

body {
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 2rem;
  text-align: center;
  color: $color-white;
  background: $color-black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a { 
  color: inherit;
  font-weight: bold;
  text-decoration: none;
  border-bottom: 2px solid $color-white;
  transition: color 0.1s linear;

  &:hover {
    color: $color-purple;
  }
}

p {
  margin: 3rem auto;
  max-width: $content-width;

  &:first-child {
    margin-top: 0;
  }
}

br {
  &.show-mobile {
    @include desktop {
      display: none;
    }
  }
}

img {
  width: 100%;

  &.card-spread {
    display: inline-block;
    max-width: 82.6rem;

    @include desktop {
      margin-top: 3rem;
    }
  }
}

.container {
  margin: 0 auto;
  padding: 0 2rem;
  
  @include desktop {
    width: 100%;
    max-width: $container-width;
  }
}

.text {
  &.blue { color: $color-blue; }
  &.purple { color: $color-purple; }
  &.white { color: $color-white; }
}
