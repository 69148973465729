.intro {
  padding-bottom: 5rem;

  @include desktop {
    padding-bottom: 10rem;
    font-size: 3.9rem;
    line-height: 4.6rem;
  }

  strong {
    color: $color-blue;
  }
  
  .video {
    position:relative;
    height:0;
    margin: 3rem auto;
    padding-bottom:56.25%;
    overflow:hidden;

    @include desktop {
      margin-top: 6rem;
      margin-bottom: 6rem;
    }
    
    iframe {
      position:absolute;
      top:0;
      left:0;
      height:100%;
      width:100%;
    }
  }
}