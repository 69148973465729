header {
  margin: 3rem auto;
  text-align: center;

  @include desktop {
    margin-top: 15rem;
    margin-bottom: 6rem;
  }

  h1 {
    margin: 0 auto;
    font-size: 4rem;
    line-height: 4rem;

    @include desktop {
      width: 83rem;
      font-size: 8.5rem;
      line-height: 8.1rem;
    }
  }

  h2 {
    margin-bottom: 1rem;
    font-size: 1.8rem;
    line-height: 2.2rem;
    letter-spacing: -0.24px;

    @include desktop {
      font-size: 4rem;
      line-height: 4.8rem;
      letter-spacing: -0.54px;
    }
  }
}