.two-thousands {
  padding: 5rem 0;
  color: $color-black;
  background-color: $color-purple;

  strong {
    color: $color-white;
  }

  @include desktop {
    font-size: 4rem;
    line-height: 4.8rem;
    padding: 10rem 0;
  }
}