// Colors
$color-black: #000000;
$color-white: #FFFFFF;
$color-blue: #59B7FF;
$color-purple: #D8B3F6;

// Measurements
$container-width: 113.4rem;
$content-width: 90rem;

// Responsive
$desktop-width: 639px;