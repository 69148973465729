.button {
  position: relative;
  padding: 1.2rem 3rem 1.5rem;
  display: inline-block;
  line-height: 1em;
  color: black;
  background: $color-white;
  font-size: 1.8rem;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  border: 2px solid black;
  border-radius: 1rem;
  outline: none;
  cursor: pointer;
  transition: all 0.05s linear;
  box-shadow: 0 4px 0 0 black;

  @include desktop {
    font-size: 3rem;
    padding: 1.9rem 4rem 2.1rem;
  }

  &:hover,
  &:active {
    color: black;
    box-shadow: none;
    transform: translateY(4px);
  }
}