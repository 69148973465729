*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
}

body {
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 2rem;
  text-align: center;
  color: #FFFFFF;
  background: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  font-weight: bold;
  text-decoration: none;
  border-bottom: 2px solid #FFFFFF;
  transition: color 0.1s linear;
}
a:hover {
  color: #D8B3F6;
}

p {
  margin: 3rem auto;
  max-width: 90rem;
}
p:first-child {
  margin-top: 0;
}

@media (min-width: 639px) {
  br.show-mobile {
    display: none;
  }
}

img {
  width: 100%;
}
img.card-spread {
  display: inline-block;
  max-width: 82.6rem;
}
@media (min-width: 639px) {
  img.card-spread {
    margin-top: 3rem;
  }
}

.container {
  margin: 0 auto;
  padding: 0 2rem;
}
@media (min-width: 639px) {
  .container {
    width: 100%;
    max-width: 113.4rem;
  }
}

.text.blue {
  color: #59B7FF;
}
.text.purple {
  color: #D8B3F6;
}
.text.white {
  color: #FFFFFF;
}

.button {
  position: relative;
  padding: 1.2rem 3rem 1.5rem;
  display: inline-block;
  line-height: 1em;
  color: black;
  background: #FFFFFF;
  font-size: 1.8rem;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  border: 2px solid black;
  border-radius: 1rem;
  outline: none;
  cursor: pointer;
  transition: all 0.05s linear;
  box-shadow: 0 4px 0 0 black;
}
@media (min-width: 639px) {
  .button {
    font-size: 3rem;
    padding: 1.9rem 4rem 2.1rem;
  }
}
.button:hover, .button:active {
  color: black;
  box-shadow: none;
  transform: translateY(4px);
}

header {
  margin: 3rem auto;
  text-align: center;
}
@media (min-width: 639px) {
  header {
    margin-top: 15rem;
    margin-bottom: 6rem;
  }
}
header h1 {
  margin: 0 auto;
  font-size: 4rem;
  line-height: 4rem;
}
@media (min-width: 639px) {
  header h1 {
    width: 83rem;
    font-size: 8.5rem;
    line-height: 8.1rem;
  }
}
header h2 {
  margin-bottom: 1rem;
  font-size: 1.8rem;
  line-height: 2.2rem;
  letter-spacing: -0.24px;
}
@media (min-width: 639px) {
  header h2 {
    font-size: 4rem;
    line-height: 4.8rem;
    letter-spacing: -0.54px;
  }
}

.intro {
  padding-bottom: 5rem;
}
@media (min-width: 639px) {
  .intro {
    padding-bottom: 10rem;
    font-size: 3.9rem;
    line-height: 4.6rem;
  }
}
.intro strong {
  color: #59B7FF;
}
.intro .video {
  position: relative;
  height: 0;
  margin: 3rem auto;
  padding-bottom: 56.25%;
  overflow: hidden;
}
@media (min-width: 639px) {
  .intro .video {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
}
.intro .video iframe {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.two-thousands {
  padding: 5rem 0;
  color: #000000;
  background-color: #D8B3F6;
}
.two-thousands strong {
  color: #FFFFFF;
}
@media (min-width: 639px) {
  .two-thousands {
    font-size: 4rem;
    line-height: 4.8rem;
    padding: 10rem 0;
  }
}

.store {
  display: block;
  padding: 5rem 0;
  color: #000000;
  background-color: #FFFFFF;
}
.store:hover {
  color: #000000;
}
.store:hover .button {
  box-shadow: none;
  transform: translateY(4px);
}
@media (min-width: 639px) {
  .store {
    font-size: 4rem;
    line-height: 4.8rem;
    padding: 10rem 0;
  }
}

.faq {
  padding: 5rem 0;
  line-height: 2.4rem;
}
@media (min-width: 639px) {
  .faq {
    padding: 10rem 0;
    font-size: 3rem;
    line-height: 3.6rem;
  }
}
.faq h3 {
  margin-bottom: 3rem;
  font-size: 3.6rem;
  line-height: 3.8rem;
  letter-spacing: -0.49px;
}
@media (min-width: 639px) {
  .faq h3 {
    margin-bottom: 6rem;
    font-size: 6.5rem;
    line-height: 7.7rem;
    letter-spacing: -0.88px;
  }
}
.faq ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
}
.faq p {
  margin: 0 0 3rem;
}
.faq .question {
  border-bottom: 2px solid #FFFFFF;
}
.faq .answer {
  display: block;
  font-weight: normal;
  margin-top: 0.75rem;
}

footer {
  font-weight: normal;
}
@media (min-width: 639px) {
  footer {
    padding-bottom: 6rem;
    text-align: left;
  }
}
footer a {
  white-space: nowrap;
}
footer p {
  margin: 2rem auto;
}
footer .logo {
  flex: 1;
}
footer .logo img {
  width: 5rem;
}
@media (min-width: 639px) {
  footer .logo img {
    display: none;
  }
}
footer .logo img.desktop {
  display: none;
  width: 20rem;
}
@media (min-width: 639px) {
  footer .logo img.desktop {
    display: block;
  }
}
footer .container .row {
  padding: 3rem 0;
  border-top: 2px solid #FFFFFF;
}
@media (min-width: 639px) {
  footer .container .row {
    display: flex;
    flex-direction: row-reverse;
  }
}
@media (min-width: 639px) {
  footer .container .col {
    margin-left: 6rem;
  }
  footer .container .col.logo {
    margin-left: 0;
  }
}