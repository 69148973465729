.store {
  display: block;
  padding: 5rem 0;
  color: $color-black;
  background-color: $color-white;

  &:hover {
    color: $color-black;

    .button {
      box-shadow: none;
      transform: translateY(4px);
    }
  }

  @include desktop {
    font-size: 4rem;
    line-height: 4.8rem;
    padding: 10rem 0;
  }
}