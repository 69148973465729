footer {
  font-weight: normal;

  @include desktop {
    padding-bottom: 6rem;
    text-align: left;
  }

  a {
    white-space: nowrap;
  }

  p {
    margin: 2rem auto;
  }

  .logo {
    flex: 1;

    img {
      width: 5rem;

      @include desktop {
        display: none;
      }
    }

    img.desktop {
      display: none;
      width: 20rem;

      @include desktop {
        display: block;
      }
    }
  }

  .container {
    .row {
      padding: 3rem 0;
      border-top: 2px solid $color-white;

      @include desktop {
        display: flex;
        flex-direction: row-reverse;
      }
    }

    .col {
      @include desktop {
        margin-left: 6rem;

        &.logo { margin-left: 0; }
      }
    }
  }
}